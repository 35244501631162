import React, { useState } from 'react';
import * as style from './menu.module.scss';
import close from 'assets/img/close.png';
import classNames from 'classnames';
import { Link } from 'gatsby';
import { ScreenType, Sections } from '../../enums/types';
import translations from './menuTranslations';
import { useIntl, FormattedMessage } from 'react-intl';
import MobileNav from 'components/mobileNav/mobileNav';
import routes from 'languages/pageTranslations/routes.json';

interface Props {
	menuType?: ScreenType;
	toggle: () => void;
	menuOpen: boolean;
	toggleContact: () => void;
}

const Menu = ({ menuType, toggle, menuOpen, toggleContact }: Props) => {
	const { formatMessage, locale } = useIntl();
	const [selected, setSelected] = useState<Sections>(Sections.Solutions);
	let isMobile = false;

	if (typeof window !== `undefined`) {
		isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
	}

	const nav = {
		solutions: {
			title: formatMessage(translations.navSolutions),
			subItems: [
				{
					title: formatMessage(translations.navWealthManagement),
					link: '/wealth-management/',
					subItems: [
						{
							title: formatMessage(translations.navDiscretionary),
							link: '/wealth-management/',
						},
						{
							title: formatMessage(translations.navAdvisory),
							link: '/wealth-management/'
						},
					],
				},
				{
					title: formatMessage(translations.navAssetManagement),
					link: '/asset-management/',
					subItems: [
						{
							title: formatMessage(translations.navPortfolios),
							link: '/asset-management/'
						},
						{
							title: formatMessage(translations.navBonds),
							link: '/asset-management/'
						},
						{
							title: formatMessage(translations.navInvestments),
							link: '/asset-management/'
						},
						{
							title: formatMessage(translations.navFunds),
							link: '/asset-management/'
						},
						{
							title: formatMessage(translations.navStrategy),
							link: '/asset-management/'
						},
						{
							title: formatMessage(translations.navHedgeFunds),
							link: '/asset-management/'
						},
					],
				},
				{
					title: formatMessage(translations.navPrivateBanking),
					link: '/private-banking/',
					subItems: [
						{
							title: formatMessage(translations.navAccountServices),
							link: '/private-banking/',
						},
						{
							title: formatMessage(translations.navDeposits),
							link: '/private-banking/',
						},
						{
							title: formatMessage(translations.navTransfers),
							link: '/private-banking/',
						},
						{
							title: formatMessage(translations.navExchangeServices),
							link: '/private-banking/',
						},
						{
							title: formatMessage(translations.navCardServices),
							link: '/private-banking/',
						},
						{
							title: formatMessage(translations.navMobileBanking),
							link: '/private-banking/',
						},
						{
							title: formatMessage(translations.navDemoAccount),
							link: '/private-banking/',
						},
					],
				},
			],
		},
		aboutTheBank: {
			title: formatMessage(translations.navAbout),
			subItems: [
				{
					title: formatMessage(translations.navAboutUs),
					link: '/about-us/',
					subItems: [
						{
							title: formatMessage(translations.navPhilosophy),
							link: '/about-us/',
						},
						{
							title: formatMessage(translations.navTeam),
							link: '/about-us/',
						},
					],
				},
				{
					title: formatMessage(translations.navCompliance),
					link: '/compliance/',
					subItems: [
						{
							title: formatMessage(translations.navDocuments),
							link: '/compliance/',
						},
					],
				},
				{
					title: formatMessage(translations.navContact),
					link: '/about-us/',
					subItems: [],
				},
			],
		},
		socialResponsibility: {
			title: formatMessage(translations.navSocialResponsibility),
			subItems: [],
		},
	};

	const subItems = nav[selected].subItems || [];

	const [subSelected, setSubSelected] = useState('Wealth Management');
	const [subSubItems, setSubSubItems] = useState(
		nav[selected].subItems.length > 0 ? nav[selected].subItems[0].subItems : []
	);

	const handleMainHover = (section: Sections) => {
		setSelected(section);
		setSubSubItems(nav[section].subItems.length > 0 ? nav[section].subItems[0].subItems : []);
	};

	const handleSubHover = (title: string) => {
		setSubSelected(title);
		setSubSubItems(subItems.find((item) => item.title === title)?.subItems || []);
	};

	const handleContactClick = () => {
		toggle();
		toggleContact()
	}

	const handleClick = (title: string) => {
		if (isMobile) {
			setSubSelected(title);
			setSubSubItems(subItems.find((item) => item.title === title)?.subItems || []);
		}
		else {
			toggle();
		}
	}

	return (
		<>
			{menuType !== 'mobile' ? (
				<div className={style.wrapper}>
					<img src={close} className={style.close} onClick={toggle} />
					<div className={style.menuContainer}>
						<ul className={style.headerList}>
							<li
								onMouseEnter={() => handleMainHover(Sections.Solutions)}
								className={classNames({
									[style.active]: selected === Sections.Solutions,
								})}
							>
								<Link to={routes[locale]["/wealth-management"]} onClick={toggle}>
									{formatMessage(translations.navSolutions)}
								</Link>
							</li>
							<li
								onMouseEnter={() => handleMainHover(Sections.AboutTheBank)}
								className={classNames({
									[style.active]: selected === Sections.AboutTheBank,
								})}
							>
								<Link to={routes[locale]["/about-us"]} onClick={toggle}>
									{formatMessage(translations.navAbout)}
								</Link>
							</li>
							<li onMouseEnter={() => handleMainHover(Sections.SocialResponsibility)}>
								<Link to={routes[locale]["/social-responsibility"]} onClick={toggle}>
									{formatMessage(translations.navSocialResponsibility)}
								</Link>
							</li>
						</ul>
						<ul className={style.itemContainer}>
							<li className={style.mainItems}>
								{subItems.map((item) => {
									return (
										<>
											{item.title === translations.navContact.defaultMessage ?
												(
													<div
														key={item.title}
														onMouseEnter={() => handleSubHover(item.title)}
														className={classNames({
															[style.active]: subSelected === item.title,
														})}
														onClick={handleContactClick}
													>
														{item.title}
													</div>
												) :
												(
													<Link
														key={item.title}
														to={!isMobile ? routes[locale][item.link] : ""}
														onMouseEnter={() => handleSubHover(item.title)}
														className={classNames({
															[style.active]: subSelected === item.title,
														})}
														onClick={() => handleClick(item.title)}
													>
														{item.title}
													</Link>
												)
											}
										</>
									);
								})}
							</li>
							<li className={style.subItems}>
								{subSubItems.map((item) => {
									return (
										<Link key={item.title} to={routes[locale][item.link]} onClick={toggle}>
											{item.title}
										</Link>
									);
								})}
							</li>
						</ul>
					</div>
					{subSelected === 'Wealth Management' ||
						subSelected === 'Asset Management' ? (
						<div className={style.disclaimer}>
							<FormattedMessage
								id="disclaimer.material"
								defaultMessage="The material contained herein is intended to be used for information purposes only and is not intended to and does not constitute investment advice. Further, it is not intended to and does not constitute an offer, invitation, solicitation or promotion of securities in any jurisdiction by any person; it is not intended to constitute an information memorandum, prospectus, selling or other similar document under the laws of any jurisdiction. In preparing the information contained herein, no account was taken of the investment objectives, financial situation or particular needs of any person. Before making any investment decision, individuals must obtain and carefully review the formal offering documents relating to particular investments. All information contained herein is intended only for non-U.S. persons and is not intended to be provided within the United States. If the information contained herein relates to any securities or investment units of any kind, they have not been registered under any federal or state securities laws of the United States and are not intended to be sold to U.S. persons or in the United States."
							/>
						</div>
					) : (
						<div className={style.disclaimer}>
							<FormattedMessage
								id="disclaimer.nb"
								defaultMessage="NB! Access to the information contained on this website may be restricted by laws and regulations applicable to the users residing in certain jurisdictions. Applicable legal requirements may prevent certain visitors from being solicited, offered certain services or financial products and engage in business with Pacific Private Bank. Please note that the financial services described in this website do not constitute an offer to transact business in any jurisdiction where such an offer would be considered unlawful, including the US, Canada, Japan, Australia, Switzerland, UK and British Isles or any of EEA country. The information provided on this website does not constitute an offer or solicitation to buy or sell any banking service, security, investment fund, investment advice or other product or service to anyone in any jurisdiction in which an offer or solicitation is not authorized or cannot legally be made or to any person to whom it is unlawful to make an offer or solicitation. If you choose to access this website, you do so at your own initiative and risk and are responsible for compliance with applicable laws, rules and regulations that may apply to you."
							/>
						</div>
					)}
				</div>
			) : (
				<MobileNav menuOpen={menuOpen} toggle={toggle} toggleContact={toggleContact} />
			)}
		</>
	);
};

export default Menu;
