exports.components = {
  "component---src-layout-news-template-tsx-content-file-path-src-news-information-common-reporting-standard-crs-mdx": () => import("./../../../src/layout/newsTemplate.tsx?__contentFilePath=/usr/src/app/src/news/information-common-reporting-standard-crs.mdx" /* webpackChunkName: "component---src-layout-news-template-tsx-content-file-path-src-news-information-common-reporting-standard-crs-mdx" */),
  "component---src-layout-news-template-tsx-content-file-path-src-news-internet-banking-now-available-at-pacific-private-bank-mdx": () => import("./../../../src/layout/newsTemplate.tsx?__contentFilePath=/usr/src/app/src/news/internet-banking-now-available-at-pacific-private-bank.mdx" /* webpackChunkName: "component---src-layout-news-template-tsx-content-file-path-src-news-internet-banking-now-available-at-pacific-private-bank-mdx" */),
  "component---src-layout-news-template-tsx-content-file-path-src-news-new-improved-web-design-mdx": () => import("./../../../src/layout/newsTemplate.tsx?__contentFilePath=/usr/src/app/src/news/new-improved-web-design.mdx" /* webpackChunkName: "component---src-layout-news-template-tsx-content-file-path-src-news-new-improved-web-design-mdx" */),
  "component---src-layout-news-template-tsx-content-file-path-src-news-pacific-private-bank-fee-and-charges-structure-changes-mdx": () => import("./../../../src/layout/newsTemplate.tsx?__contentFilePath=/usr/src/app/src/news/pacific-private-bank-fee-and-charges-structure-changes.mdx" /* webpackChunkName: "component---src-layout-news-template-tsx-content-file-path-src-news-pacific-private-bank-fee-and-charges-structure-changes-mdx" */),
  "component---src-layout-news-template-tsx-content-file-path-src-news-pacific-private-bank-in-the-banking-conference-sibos-2016-mdx": () => import("./../../../src/layout/newsTemplate.tsx?__contentFilePath=/usr/src/app/src/news/pacific-private-bank-in-the-banking-conference-sibos-2016.mdx" /* webpackChunkName: "component---src-layout-news-template-tsx-content-file-path-src-news-pacific-private-bank-in-the-banking-conference-sibos-2016-mdx" */),
  "component---src-layout-news-template-tsx-content-file-path-src-news-pacific-private-bank-is-acquired-by-fintech-entrepreneurs-mdx": () => import("./../../../src/layout/newsTemplate.tsx?__contentFilePath=/usr/src/app/src/news/pacific-private-bank-is-acquired-by-fintech-entrepreneurs.mdx" /* webpackChunkName: "component---src-layout-news-template-tsx-content-file-path-src-news-pacific-private-bank-is-acquired-by-fintech-entrepreneurs-mdx" */),
  "component---src-layout-news-template-tsx-content-file-path-src-news-pacific-private-bank-team-attended-sibos-2017-mdx": () => import("./../../../src/layout/newsTemplate.tsx?__contentFilePath=/usr/src/app/src/news/pacific-private-bank-team-attended-sibos-2017.mdx" /* webpackChunkName: "component---src-layout-news-template-tsx-content-file-path-src-news-pacific-private-bank-team-attended-sibos-2017-mdx" */),
  "component---src-layout-news-template-tsx-content-file-path-src-news-pacific-private-bank-welcomes-new-chief-commercial-officer-mdx": () => import("./../../../src/layout/newsTemplate.tsx?__contentFilePath=/usr/src/app/src/news/pacific-private-bank-welcomes-new-chief-commercial-officer.mdx" /* webpackChunkName: "component---src-layout-news-template-tsx-content-file-path-src-news-pacific-private-bank-welcomes-new-chief-commercial-officer-mdx" */),
  "component---src-layout-news-template-tsx-content-file-path-src-news-pacific-private-bank-welcomes-new-chief-operating-officer-mdx": () => import("./../../../src/layout/newsTemplate.tsx?__contentFilePath=/usr/src/app/src/news/pacific-private-bank-welcomes-new-chief-operating-officer.mdx" /* webpackChunkName: "component---src-layout-news-template-tsx-content-file-path-src-news-pacific-private-bank-welcomes-new-chief-operating-officer-mdx" */),
  "component---src-layout-news-template-tsx-content-file-path-src-news-ppb-presents-at-the-ict-days-2019-mdx": () => import("./../../../src/layout/newsTemplate.tsx?__contentFilePath=/usr/src/app/src/news/ppb-presents-at-the-ict-days-2019.mdx" /* webpackChunkName: "component---src-layout-news-template-tsx-content-file-path-src-news-ppb-presents-at-the-ict-days-2019-mdx" */),
  "component---src-layout-news-template-tsx-content-file-path-src-news-toma-zarzeckyte-a-certified-anti-money-laundering-specialist-mdx": () => import("./../../../src/layout/newsTemplate.tsx?__contentFilePath=/usr/src/app/src/news/toma-zarzeckyte-a-certified-anti-money-laundering-specialist.mdx" /* webpackChunkName: "component---src-layout-news-template-tsx-content-file-path-src-news-toma-zarzeckyte-a-certified-anti-money-laundering-specialist-mdx" */),
  "component---src-layout-news-template-tsx-content-file-path-src-news-vanuatu-joins-interpol-mdx": () => import("./../../../src/layout/newsTemplate.tsx?__contentFilePath=/usr/src/app/src/news/vanuatu-joins-interpol.mdx" /* webpackChunkName: "component---src-layout-news-template-tsx-content-file-path-src-news-vanuatu-joins-interpol-mdx" */),
  "component---src-layout-news-template-tsx-content-file-path-src-news-vanuatu-receives-fatf-recognition-mdx": () => import("./../../../src/layout/newsTemplate.tsx?__contentFilePath=/usr/src/app/src/news/vanuatu-receives-fatf-recognition.mdx" /* webpackChunkName: "component---src-layout-news-template-tsx-content-file-path-src-news-vanuatu-receives-fatf-recognition-mdx" */),
  "component---src-layout-news-template-tsx-content-file-path-src-news-viktorija-at-the-cams-conference-in-tokyo-mdx": () => import("./../../../src/layout/newsTemplate.tsx?__contentFilePath=/usr/src/app/src/news/viktorija-at-the-cams-conference-in-tokyo.mdx" /* webpackChunkName: "component---src-layout-news-template-tsx-content-file-path-src-news-viktorija-at-the-cams-conference-in-tokyo-mdx" */),
  "component---src-layout-news-template-tsx-content-file-path-src-news-viktorija-sadauskaite-certified-anti-money-laundering-specialist-mdx": () => import("./../../../src/layout/newsTemplate.tsx?__contentFilePath=/usr/src/app/src/news/viktorija-sadauskaite-certified-anti-money-laundering-specialist.mdx" /* webpackChunkName: "component---src-layout-news-template-tsx-content-file-path-src-news-viktorija-sadauskaite-certified-anti-money-laundering-specialist-mdx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-asset-management-tsx": () => import("./../../../src/pages/asset-management.tsx" /* webpackChunkName: "component---src-pages-asset-management-tsx" */),
  "component---src-pages-bank-holidays-tsx": () => import("./../../../src/pages/bank-holidays.tsx" /* webpackChunkName: "component---src-pages-bank-holidays-tsx" */),
  "component---src-pages-compliance-tsx": () => import("./../../../src/pages/compliance.tsx" /* webpackChunkName: "component---src-pages-compliance-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-information-security-policy-tsx": () => import("./../../../src/pages/information-security-policy.tsx" /* webpackChunkName: "component---src-pages-information-security-policy-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-private-banking-tsx": () => import("./../../../src/pages/private-banking.tsx" /* webpackChunkName: "component---src-pages-private-banking-tsx" */),
  "component---src-pages-social-responsibility-tsx": () => import("./../../../src/pages/social-responsibility.tsx" /* webpackChunkName: "component---src-pages-social-responsibility-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-pages-wealth-management-tsx": () => import("./../../../src/pages/wealth-management.tsx" /* webpackChunkName: "component---src-pages-wealth-management-tsx" */)
}

